export enum CharacterNames {
  AngryFace = 'Angry Face',
  Brute = 'Brute',
  Circles = 'Circles',
  Cragheart = 'Cragheart',
  Eclipse = 'Eclipse',
  Lightning = 'Lightning',
  Mindthief = 'Mindthief',
  MusicNote = 'Music Note',
  Saw = 'Saw',
  Scoundrel = 'Scoundrel',
  Spellweaver = 'Spellweaver',
  Squidface = 'Squidface',
  Sun = 'Sun',
  ThreeSpears = 'Three Spears',
  Tinkerer = 'Tinkerer',
  Triangles = 'Triangles',
  TwoMini = 'Two Mini',
}
